import React, { FC } from 'react';
import AddToBasket from '@src/components/add-to-basket';
import { isUsingGBPCurrency } from '@src/services/global';
import styles from './bundle-item.module.scss';
import { formatPrice } from '@src/services/format';
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

interface IBundleItemProps {
  bundle: any;
}

const BundleItem: FC<IBundleItemProps> = ({ bundle }) => {
  const { dataPlans: data, isAvailable } = useGetProductPlansData(bundle.sku);

  const planData = data?.product_plans ? data?.product_plans[0] : null;

  return (
    <div className={styles.bundle}>
      <div className={styles.bundleContent}>
        <div className={styles.bundleHeader}>
          {planData && (
            <div className={styles.totalDiscount}>
              Save{' '}
              <span className={styles.discountAmount}>
                {formatPrice(
                  Math.round(planData?.rrpPrice - (planData?.offerPrice || 0)),
                  planData.currency,
                  0
                )}
              </span>
            </div>
          )}
          {bundle.title}
        </div>
        <div className={styles.bundleBody}>
          <div className={styles.priceProductWrap}>
            {planData && (
              <div className={styles.bundlePrice}>
                <span className={styles.rrpPrice}>
                  {formatPrice(planData?.rrpPrice || bundle.rrp_price, planData?.currency)}
                </span>
                <span className={styles.offerPrice}>
                  {formatPrice(planData?.offerPrice || bundle.offer_price, planData?.currency)}
                </span>
              </div>
            )}
            <ul className={styles.bundleProducts}>
              {bundle.product_items.length > 0 &&
                bundle.product_items.map((product: any) => (
                  <li key={product.name} className={styles.bundleProduct}>
                    <img
                      className={styles.iconCheck}
                      alt="Checked icon"
                      width="18"
                      src={'/images/icon_check.svg'}
                    />
                    {product.name}
                  </li>
                ))}
            </ul>
          </div>
          <div className={styles.descButtonWrap}>
            <AddToBasket
              isBundle={true}
              showButtonOnly={true}
              sku={bundle.sku}
              isAvailable={isAvailable && isUsingGBPCurrency()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundleItem;

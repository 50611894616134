import React from 'react';
import { Link } from 'gatsby';
// components
import Layout from '@components/layout';
import SEO from '@components/seo';
import JoinTheCommunity from '@components/join-the-community/join-the-community';
import Reviews from '@components/reviews/reviews';
import Image from '@components/image/image';
import StillHaveQuestion from '@components/still-have-question/still-have-question';
import HowItWorks from '@components/how-it-works';
// styles
import styles from './bundles.module.scss';
// content
import { useBundlesText } from '@hooks/directusHooks/useBundlesText';
import { SwiperComponent } from 'vitl-component-library';
import BundleItem from '@src/features/bundles/components/BundleItem';

const Bundles = () => {
  const directus = useBundlesText();

  return (
    <Layout>
      <SEO title={directus.page_title} description={directus.page_description} />

      <section className={styles.hero}>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.heroSliderCol}>
              <div className={styles.heroSliderContent}>
                <SwiperComponent paginationType="bullets" spaceBetween={20} slidesPerView={1}>
                  {directus.hero_slider_items.map((image, index: number) => (
                    <div key={index} className={styles.item}>
                      <Image filename={image.filename_disk} alt={image.title} width={530} />
                    </div>
                  ))}
                </SwiperComponent>
              </div>
            </div>
            <div className={styles.heroTextCol}>
              <div className={styles.heroTextContent}>
                <h1>{directus.hero_title}</h1>
                <hr className={styles.hrDark} />
                <div
                  className={styles.heroDescription}
                  dangerouslySetInnerHTML={{
                    __html: directus.hero_description,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionBundles}>
        <div className={styles.arrowContainer}>
          <Image alt="Down arrow" width={18} src={'/images/icon-long-arrow-down.svg'} />
        </div>
        <h2>{directus.bundles_title}</h2>
        <hr className={styles.hr} />
        <div className={styles.bundlesDescription} dangerouslySetInnerHTML={{ __html: directus.bundles_description }} />
        <div className={styles.bundlesContainer}>
          <div className={styles.bundlesRow}>
            {directus.bundle_items.length > 0 &&
              directus.bundle_items.map((bundle: any) => (
                <BundleItem key={bundle.title} bundle={bundle} />
              ))}
          </div>
        </div>
      </section>

      <HowItWorks
        title={directus.thing_you_will_learn_title}
        items={directus.thing_you_will_learn_items}
        isWithCheckmarks={true}
      />

      <Reviews
        title={directus.reviews_title}
        items={directus.reviews_items}
        showReview
        hideTrustPilot
        variationYellow
      />

      <section className={styles.sectionWhatsInside}>
        <div className={styles.whatsInsideContainer}>
          <h2>{directus.whats_inside_title}</h2>
          <hr className={styles.hr} />
          <div className={styles.whatsInsideRow}>
            {directus.whats_inside_items.length > 0 &&
              directus.whats_inside_items.map((p, i: number) => (
                <div key={i} className={styles.colWhatsInside}>
                  <div className={styles.whatsInsideContent}>
                    <div className={styles.imageRoundFrame}>
                      <Image width={140} filename={p.filename_disk} alt={'Product Image'} />
                    </div>
                    <h3>{p.title}</h3>
                    <p>{p.description}</p>
                    {p.action_label === 'Learn more' ? (
                      <Link className={styles.buttonLearnMore} to={p.action_path}>
                        {p.action_label}
                      </Link>
                    ) : (
                      <div className={styles.noButton}>{p.action_label}</div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      <HowItWorks title={directus.hiw_title} items={directus.hiw_items} />

      <section className={styles.science}>
        <div className={styles.containerFlex}>
          <div className={`${styles.side} ${styles.textSide}`}>
            <h2>{directus.science_title}</h2>
            <hr className={styles.hr} />
            <p
              dangerouslySetInnerHTML={{
                __html: directus.science_description,
              }}
            />
          </div>
          <div className={styles.side}>
            <picture className={styles.image}>
              <Image width={489} filename={directus.science_image.filename_disk} alt={'Product Image'} />
            </picture>
          </div>
        </div>
      </section>

      <StillHaveQuestion accordion={directus.faqs} description={directus.faq_description} />

      <JoinTheCommunity />
    </Layout>
  );
};

export default Bundles;

import { graphql, useStaticQuery } from 'gatsby';
import { IDirectusBundleData } from '../../types/directus/bundles';

export const useBundlesText = () => {
  const { directusBundle } = useStaticQuery<IDirectusBundleData>(graphql`
    query Bundle {
      directusBundle {
        page_title
        page_description
        hero_title
        hero_description
        hero_slider_items {
          filename_disk
          title
        }
        bundles_title
        bundles_description
        bundle_items {
          title
          product_items {
            name
          }
          button_label
          sku
          rrp_price
          offer_price
          save_amount
        }
        thing_you_will_learn_title
        thing_you_will_learn_items {
          title
          description
        }
        whats_inside_title
        whats_inside_items {
          filename_disk
          title
          description
          action_label
          action_path
        }
        science_title
        science_description
        science_image {
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        hiw_title
        hiw_items {
          title
          description
          filename_disk
        }
        faq_description
        faqs {
          question
          answer
        }
      }
    }
  `);
  return directusBundle;
};

import React from 'react';
import { StillHaveQuestionUI } from 'vitl-component-library';

export interface IAccordion {
  question: string;
  answer: string;
}

export interface IProps {
  accordion: IAccordion[];
  description: string;
}

const StillHaveQuestion = ({ accordion, description }: IProps) => {
  return <StillHaveQuestionUI accordion={accordion} description={description} />;
};

export default StillHaveQuestion;
